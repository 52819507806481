<template>
  <section class="w-full border-t-[1px] border-gray-200 pc:mt-[100px] pc:mb-[140px] mobile:mt-[70px] mobile:mb-[88px]">
    <div class="flex justify-center ">
      <div class="flex flex-col pc:w-[1280px] pc:px-0 mobile:w-full tablet:px-[32px] mobile:px-[16px] ">
        <div
          class="
            pc:text-[36px] pc:leading-[1.28] pc:tracking-[-0.3px] pc:pt-[64px]
            tablet:text-[36px] tablet:leading-[1.28] tablet:tracking-[-0.3px] tablet:pt-[64px]
            mobile:text-[22px] mobile:leading-[1.28] mobile:tracking-[-0.3px] mobile:pt-[32px]
            text-gray-700 font-bold
          "
          data-aos="fade-up"
          data-aos-duration="900"
        >
          지원 및 면접 가이드
        </div>
        <div class="flex flex-wrap w-full gap-[24px]  pc:mt-[32px] mobile:mt-[20px]">
          <div
            v-for="(item, i) in items"
            :key="i"
            class="
              pc:basis-[calc(33.333%-16px)]
              tablet:basis-[calc(50%-16px)]
              mobile:w-full
              aspect-[16/9] rounded-[8px]
              bg-cover relative
              overflow-hidden cursor-pointer
            "
            :style="{
              backgroundImage: `url('${item.img}')`
            }"
            data-aos="fade-up"
            data-aos-duration="900"
            @click="$router.push({path: item.link, hash: item.hash})"
          > 
            <div
              class="w-full h-full flex justify-center items-center text-white font-bold
                pc:text-[30px] pc:leading-[1.27] pc:tracking-[-0.3px] pc:bg-[rgba(0,0,0,0.4)]
                mobile:text-[26px] mobile:leading-[1.23] mobile:tracking-[-0.2px] mobile:bg-[rgba(0,0,0,0.5)]
                text-center
                hover:bg-[rgba(0,0,0,0.6)]
              "
              v-html="item.title"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Guide',
  data() {
    return {
      items:[{
        title: '이력서 및 포트폴리오<br/>가이드',
        a:'1',
        img: require('@/assets/careers/resume.png'),
        link:'/story/103',
      }, {
        title: '쿡앱스 오는 길',
        img: require('@/assets/careers/pangyo.png'),
        link:'/story/85',
      }, {
        title: '자주 묻는 질문',
        img: require('@/assets/careers/faq.png'),
        link:'/faq',
      }]
    };
  },
};
</script>

<style lang="scss" scoped>
</style>